<template>
    <div class="bg_0">
        <img src="@/assets/images/index/banner2.png" alt="" class="banner m-b-10">
        <div class="bg_fa index text-left">
            <div class="f0">
                <div class="m-t-40" v-if="0">
                    <el-radio-group v-model="queryObj.identityId" @change="change">
                        <el-radio-button :label="v.id" v-for="(v,i) of ltIdentityList" :key="v.id">{{v.identityName}}</el-radio-button>
                    </el-radio-group>
                </div>
                <div v-if="content && content.length">
                    <div class="boxs">
                        <div class="box" v-for="(v,i) of content" :key="i">
                            <div class="item pointer" @click="goto(v)">
                                <div class="itemTop df-jc-s-b">
                                    <div>
                                        <!-- <img src="@/assets/images/index/VIP.png" alt="" class="icon" v-if="v.vipLeve==2"> -->
                                        <div class="enterpriseSecurityDepositFees">
                                            <img src="@/assets/images/index/keep.png" alt="" class="icon" v-if="v.enterpriseSecurityDepositFee">
                                            <span class="enterpriseSecurityDepositFee">{{v.enterpriseSecurityDepositFee}}</span>
                                        </div>
                                    </div>
                                    <div class="name line-1" v-if="v.enterpriseShopName">{{v.enterpriseShopName}}</div>
                                </div>
                                <img v-if="getImgUrl(v,i)" :src="$oucy.ossUrl + getImgUrl(v,i)" alt="" class="img" object-fit="contain">
                                <img v-else src="@/assets/images/index/default.jpg" alt="" class="img" object-fit="contain">
                                <div class="itemBottom">
                                    <div class="line-1">{{v.enterpriseSignature}}</div>
                                </div>
                                <div class="itemBox">
                                    <div class="df-ai-c">
                                        <div class="logos">
                                            <img :src="$oucy.ossUrl + v.enterpriseAvata" alt="" class="logo">
                                        </div>
                                        <div class="line-1  p-l-10">{{v.enterpriseName}}</div>
                                    </div>
                                    <div class="line-2 m-t-5" v-if="v.enterpriseIntro" v-html="v.enterpriseIntro"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="noMore && (!content || !content.length)" class="notData">
                    <img src="@/assets/images/index/notData.png" alt="">
                    <div>- 暂无任何企业数据 -</div>
                </div>
                <div class="loading">
                    <p v-if="loading && !noMore">加载中...</p>
                    <p v-if="noMore">- 没有更多了 -</p>
                </div>
                <scrollPage v-if="content && content.length && queryObj.start>0" class="scrollPage" text="这个是预加载的" @load="load" :hasMore="!noMore"></scrollPage>
                <!-- <scrollPage @load="load" :hasMore="!noMore"></scrollPage> -->
                <Observer @callBack="callBack" id="indexNewObserver"></Observer>
            </div>
        </div>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import { enterpriseinfo, ltIdentity } from "@/service"
export default {
    name: "Index",
    data() {
        return {
            requestObj: {},
            // 请求对象
            queryObj: {
                enterpriseAuthenticationLevel: 2, //    0全部 1实名认证 2信守约 3品质工厂
                limit: 12,
                start: 0,
                identityId: null,
            },
            ltIdentityList: [],
            content: [],
            pageSizes: [30, 60, 120],
            loading: false,
            noMore: false,
            hasObserver:false,
        }
    },
    created(){
        let width = document.body.clientWidth

        if (width > 1921) {
            this.queryObj.limit = 10
        }
        this.load()
    },
    mounted() {
    },
    computed: {},
    methods: {
        load() {
            if (!this.noMore && !this.loading) {
                this.queryEnterpriseLibrary()
            }
            this.loading = true
        },
        change() {
            this.content = []
            this.queryObj.start = 0
            this.queryEnterpriseLibrary()
        },
        findAllLtIdentity() {
            ltIdentity.findAllLtIdentity({ identityIsEnterprise: true }).then(res => {
                if (res) {
                    this.ltIdentityList = res
                }
            })
        },
        queryEnterpriseLibrary() {
            enterpriseinfo.queryEnterpriseLibrary(this.queryObj).then(res => {
                if (res) {
                    // this.requestObj = res
                    this.noMore = res.last
                        ++this.queryObj.start
                    this.content.push(...res.content || [])
                    if(!this.noMore && this.hasObserver){
                        this.queryEnterpriseLibrary()
                    }else{
                        this.loading = false                        
                    }
                }
            })
        },

        goto(v) {
            if (v.vipLeve == 2 || v.vipLeve== 1) {
                this.$oucy.openNewPage("/ShopVip?enterpriseId=" + v.id);
            } else {
                this.$oucy.openNewPage("/Shop?enterpriseId=" + v.id);
            }
        },
        getImgUrl(v, i) {
            let url = null
            if (v.enterpriseImage) {
                let enterpriseImage = JSON.parse(v.enterpriseImage)
                for (let vv of enterpriseImage) {
                    let image = vv.endsWith('mp4')
                    if (image !== true) {
                        url = vv
                        break
                    }
                }
            }
            return url
        },
        callBack(e){
            this.hasObserver=!!e
        },
    },
}
</script>
<style scoped lang="less">
.index {
    padding-bottom: 60px;
    padding-left: 30px;
    padding-right: 30px;
    background: #000;
    min-width: 1200px;
}

.banner {
    width: 100%;
    min-width: 1200px;
    // margin-left: -30px;
    // margin-right: -30px;
    margin-bottom: -10%;
}

.boxs {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
}

.box {
    width: 20%;
    padding: 10px;
    box-sizing: border-box;
}

.item {
    // background: #fff;
    height: 0;
    padding-bottom: 61.73%;
    border-radius: 16px;
    position: relative;
    overflow: hidden;

    // font-size: 0;
    &:hover .itemBox {
        bottom: 0;
    }

    &:hover .itemBottom {
        bottom: -60px;
    }
}

.itemBottom {
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: saturate(100%) blur(2px);
    color: #fff;
    box-sizing: border-box;
    padding: 15px 30px;
    font-size: 12px;
    font-family: Microsoft YaHei, Microsoft YaHei-Bold;
    font-weight: 700;
    text-align: center;
    transition: bottom 0.15s;

}

.img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: auto;
}

.itemTop {
    position: absolute;
    z-index: 2;
    width: 100%;
    left: 0;
    top: 15px;
    align-items: center;

    .enterpriseSecurityDepositFees {
        position: relative;

        .enterpriseSecurityDepositFee {
            position: absolute;
            left: 50px;
            top: 6px;
            font-size: 12px;
            font-weight: bold;
            color: #fff;
            background: #59dc96;
            width: 50px;

        }
    }

    .icon {
        width: auto;
        height: 28px;
        margin-left: 20px;
    }

    .name {
        background: rgba(32, 144, 255, 0.8);
        border-radius: 4px;
        color: #fff;
        font-size: 14px;
        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
        padding: 0px 20px;
        max-width: 30%;
        height: 35px;
        line-height: 35px;
    }
}

.itemBox {
    position: absolute;
    left: 0;
    // bottom:0px;
    bottom: -125px;
    z-index: 3;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: saturate(100%) blur(2px);
    color: #fff;
    box-sizing: border-box;
    font-size: 14px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: 400;
    border-radius: 16px;
    padding: 20px;
    transition: bottom 0.15s;

}

.logos {
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .logo {
        max-width: 100%;
        max-height: 100%;
    }
}

.loading {
    text-align: center;
    font-size: 14px;
    color: #aaaaaa;
    margin-top: 20px;
}

.notData {
    font-size: 14px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: 400;
    text-align: center;
    color: #aaaaaa;
    padding: 60px 0 120px;

    img {
        width: 350px;
    }
}

@media screen and (max-width: 1920px) {
    .box {
        width: 25%;
    }
}

@media screen and (max-width: 1600px) {
    .box {
        width: 33.333%;
    }
}
.bg_0{
    background:#000;
}
// @media screen and (max-width: 1400px) {
//     .box {
//         width: 25%;
//     }
// }
.scrollPage{
    position:relative;
    top: -400px;
    opacity: 0;
    user-select: none;
}
</style>